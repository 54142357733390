/* eslint-disable react/self-closing-comp */
import React from "react"
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import styles from "../../styles/recommendation.module.css"

function IncomingAdvert(props) {
  return (
    <div className={styles.advertImport}>
      <iframe
        scrolling="no"
        frameBorder="0"
        src={props.srcLink}
        style={{
          width: `${props.frameWidth}px`,
          height: `${props.frameHeight}px`
        }}
      />
    </div>
  )
}

function DesignAdvert(props) {
  const sanityConfig = { projectId: "cyo8ldkg", dataset: "staging" }

  const constructLink = item => {
    if (!item.params || item.params.length === 0) {
      return item.href
    }
    return item.href + item.params.join("&")
  }

  const link = constructLink(props)
  const imageAssetId = props.mainImage.asset._id
  const fluidProps = getFluidGatsbyImage(imageAssetId, { maxHeight: 600 }, sanityConfig)

  const proposedResult = (
    <div className={styles.container}>
      <Img fluid={fluidProps} className={styles.productImage} alt={props.mainImage.alt}></Img>
      <div className={styles.gapPanel} />
      <div className={styles.infopanel}>
        <p className={styles.linkNeeded}>
          <span>
            <a href={link} className={styles.manufacturer}>
              von {props.manufacturer}
            </a>
          </span>

          <span>
            <a href={link} className={styles.Anzeige}>
              Anzeige
            </a>
          </span>
        </p>

        <p className={styles.productname}>{props.title}</p>
        <p className={styles.excerpt}>{props.excerpt}</p>

        <a className={styles.cta} target="_blank" rel="noopener noreferrer" href={link}>
          <div className={styles.priceDiv}>
            <p className={styles.price}>
              {props.originalPrice ? (
                <span className={styles.originalPrice}>{props.originalPrice}€</span>
              ) : null}
              &nbsp;&nbsp;{props.price}€
            </p>
          </div>

          <div className={styles.ctadiv}>
            <p className={styles.ctatext}>{props.cta}</p>
          </div>
        </a>
      </div>
    </div>
  )
  return proposedResult
}

function Recommendation(props) {
  if (props.importAdvert && props.importAdvert.title !== undefined) {
    return (
      <div>
        <IncomingAdvert {...props.importAdvert} />
      </div>
    )
  }

  if (props.designAdvert && props.designAdvert.title !== undefined) {
    return (
      <div>
        <DesignAdvert {...props.designAdvert} />
      </div>
    )
  }
  return null
}

export default Recommendation
