import React from "react"
import Img from "gatsby-image"
import {
  getFluidGatsbyImage
  // getFixedGatsbyImage
} from "gatsby-source-sanity"
import styles from "../../styles/figure.module.css"

function Figure(props) {
  const visible = {
    overflow: "visible"
  }
  const { link, alt, caption, category, asset, newTab, objectFit } = props
  const sanityConfig = { projectId: "cyo8ldkg", dataset: "staging" }
  const { _id: imageAssetId } = asset
  const fluidProps = getFluidGatsbyImage(imageAssetId, { maxHeight: 500 }, sanityConfig)
  return (
    <div className={styles.figurecontainer}>
      <a href={link} target={newTab ? "_blank" : ""}>
        <div className={styles.figurebg} style={category}>
          <figure className={styles.root}>
            {props.asset && (
              <Img
                fluid={fluidProps}
                alt={alt}
                style={visible}
                imgStyle={{ left: "20px", objectFit: objectFit || "cover" }}
              />
            )}
            <figcaption className={styles.caption}>{caption}</figcaption>
          </figure>
        </div>
      </a>
    </div>
  )
}

export default Figure
