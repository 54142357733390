import React from "react"
import { Link } from "gatsby"
import { getBlogUrl } from "../../lib/helpers"

function InternalLink(props) {
  const article = getBlogUrl(
    props.mark.reference.parenttag.slug.current,
    props.mark.reference.slug.current,
    props.mark.reference.articleTier
  )

  return <Link to={`/${article}`}>{props.children}</Link>
}
export default InternalLink
