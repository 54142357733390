import React from "react"
import styles from "../../styles/dropcap.module.css"

function DropCap(props) {
  return (
    <span style={props.category} className={styles.dropcap}>
      {props.children}
    </span>
  )
}

export default DropCap
