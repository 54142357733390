import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion"
import "../../styles/accordeon.css"
import styles from "../../styles/mediator.module.css"

function defaultTitle(title) {
  if (title == null) {
    return "Häufige Fragen zum Artikel"
  } else {
    return title
  }
}

function Mediator(props) {
  return (
    <div>
      <p className={styles.faqhead}>{defaultTitle(props.mediator.title)}</p>
      <Accordion>
        {props.mediator.map(item => (
          <AccordionItem key={item.question}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="titlewrapper">
                  <div className="accordion-title-text">{item.question}</div>
                  <div className="arrowcontainer">
                    <div className="accordion__arrow" role="presentation" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>{item.answer}</AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}

export default Mediator
