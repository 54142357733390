import React from "react"
import slugify from "slugify"
import styles from "../../styles/quelle.module.css"

const generateSlug = heading =>
  slugify(heading, {
    lower: true,
    replacement: "-",
    remove: /[*+~.()'"!:@]/g
  })

function QuelleLink(props) {
  return (
    <span>
      {props.children}{" "}
      <a className={styles.quelle} href={`#${generateSlug(props.mark.reference.articlename)}`} />
    </span>
  )
}
export default QuelleLink
