/* eslint-disable react/jsx-no-duplicate-props */
import BaseBlockContent from "@sanity/block-content-to-react"
import React from "react"
import CustomLink from "./customlink"
import Figure from "./figure"
import DropCap from "./dropcap"
import InfoBlock from "./infoblock"
import InternalLink from "./internallink"
import Mediator from "./mediator"
import QuelleLink from "./quelle"
import Recommendation from "./recommendation"
import Slideshow from "./slideshow"
import slugify from "slugify"
import typography from "../../styles/typography.module.css"
import getYouTubeId from "get-youtube-id"
import YouTube from "react-youtube"
import Sleeperoo from "./sleeperoo"
const generateSlug = heading =>
  slugify(heading, {
    lower: true,
    replacement: "-",
    remove: /[*+~.()'"!:@]/g
  })

const serializers = ({ category, bordercolor }) => ({
  types: {
    block(props) {
      switch (props.node.style) {
        case "h1": {
          const slug = generateSlug(props.children.map(text => text).join())
          return (
            <div className="narrow">
              <h2 id={slug} className={typography.responsiveTitle1}>
                {" "}
                {props.children}
              </h2>
            </div>
          )
        }
        case "h2": {
          const slug = generateSlug(props.children.map(text => text).join())
          return (
            <div className="narrow">
              <h2 id={slug} className={typography.responsiveTitle2}>
                {" "}
                {props.children}
              </h2>
            </div>
          )
        }
        case "h3": {
          const slug = generateSlug(props.children.map(text => text).join())
          return (
            <div className="narrow">
              <h2 id={slug} className={typography.responsiveTitle3}>
                {" "}
                {props.children}
              </h2>
            </div>
          )
        }
        case "seperator":
          return (
            <div className="narrow">
              <p className={typography.responsiveTitle4}>{props.children}</p>
            </div>
          )

        case "blockquote":
          return (
            <div className="narrow">
              <div className="quotecontainer">
                <div className="marker" style={category} />
                <div className="quote">
                  <blockquote className={typography.blockQuote}>{props.children}</blockquote>
                </div>
              </div>
            </div>
          )
        case "infoblock":
          return (
            <div className="wider">
              <div className={typography.infoblockcontainer} style={bordercolor}>
                <div className={typography.infoblockbg}>
                  <div className={typography.infoblockinner} className="narrow">
                    <p className={typography.paragraph}>{props.children}</p>
                  </div>
                </div>
              </div>
            </div>
          )

        default:
          return (
            <div className="narrow textblock">
              <p className={typography.paragraph}>{props.children}</p>
            </div>
          )
      }
    },

    figure(props) {
      return (
        <div className="wider">
          <Figure category={category} {...props.node} />
        </div>
      )
    },

    infoblockn(props) {
      return (
        <div className="wider">
          <InfoBlock category={category} bordercolor={bordercolor} {...props.node} />
        </div>
      )
    },

    slideshow(props) {
      return (
        <div className="narrow">
          <Slideshow {...props.node} />
        </div>
      )
    },

    infoAccordeon(props) {
      return (
        <div className="narrow" id="test">
          <Mediator {...props.node} />
        </div>
      )
    },

    productRec(props) {
      return (
        <div className="narrow">
          <Recommendation {...props.node} />
        </div>
      )
    },
    sleeperoo() {
      return (
        <div className="narrow">
          <Sleeperoo />
        </div>
      )
    },

    youtube(props) {
      const url = props.node.url
      const id = getYouTubeId(url)
      return (
        <div className="narrow">
          <div className={typography.youtubeContainer}>
            <YouTube videoId={id} className={typography.youtubeVideo} />
          </div>
        </div>
      )
    }
  },

  marks: {
    internalLink(props) {
      return <InternalLink {...props} />
    },

    quelle(props) {
      return <QuelleLink {...props} />
    },

    dropcap(props) {
      return <DropCap category={category} {...props} />
    },

    link(props) {
      return <CustomLink {...props} />
    }
  },

  list: props =>
    props.type === "bullet" ? (
      <div className="narrow">
        <ul className="bodyul">{props.children}</ul>{" "}
      </div>
    ) : (
      <ol className="bodyol">{props.children}</ol>
    )
})

const BlockContent = ({ blocks, category, className, bordercolor }) => (
  <BaseBlockContent
    blocks={blocks}
    className={className}
    serializers={serializers({ category, bordercolor })}
  />
)

export default BlockContent
