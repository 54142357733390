/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
import React from "react"

function CustomLink(props) {
  const blank = props.mark.blank
  const nofollow = props.mark.follow

  if (blank == true && nofollow == true) {
    return (
      <a target="_blank" rel="nofollow" href={props.mark.href}>
        {props.children}
      </a>
    )
  }
  if (blank == true && nofollow == false) {
    return (
      <a target="_blank" href={props.mark.href}>
        {props.children}
      </a>
    )
  }
  if (blank == false && nofollow == true) {
    return <a href={props.mark.href}>{props.children}</a>
  }
  if (blank == false && nofollow == false) {
    return <a href={props.mark.href}>{props.children}</a>
  }
  if (blank == undefined && nofollow == true) {
    return (
      <a rel="nofollow" href={props.mark.href}>
        {props.children}
      </a>
    )
  }
  if (nofollow == undefined && blank == true) {
    return (
      <a target="_blank" href={props.mark.href}>
        {props.children}
      </a>
    )
  }
}
export default CustomLink
