import React from "react"
import icon from "../../assets/infoboxcontent.svg"
import styles from "../../styles/infoblock.module.css"
import typography from "../../styles/typography.module.css"

function InfoBlock(props) {
  var length = props.mediator2.length - 1
  return (
    <div key={props._key} className={styles.infoblockcontainer} style={props.bordercolor}>
      <div className={styles.infoblockbg}>
        <div className={styles.infoblockinner}>
          <div className={styles.iconhead}>
            <div className={styles.icon}>
              <img src={icon} alt="Info Box Logo" />
            </div>
            <div className={styles.head}>
              <p>{props.infohead}</p>
            </div>
          </div>
          {props.mediator2.map((item, i) => (
            <div key={item._key}>
              <p className={styles.heading}>{item.question}</p>

              <p className={typography.paragraph} key={item.anwswer}>
                {item.answer}
              </p>
              {i < length ? (
                <div className={styles.zigzag}>
                  <p>&nbsp;</p>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default InfoBlock
